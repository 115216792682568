<template>
  <div class="text-white p-4 border-0 rounded relative bg-blue-500 text-sm">
    <span class="inline-block align-middle">
      <slot />
    </span>
    <!--<button
      class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
    >
      <span>×</span>
    </button>-->
  </div>
</template>

<script>
export default {}
</script>

<style></style>
