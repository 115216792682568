<template>
  <div>
    <div class="p-4 flex justify-between">
      <div>Mis experiencias</div>
      <Button :to="{ name: 'experiences.new' }">Añadir</Button>
    </div>

    <template v-if="!courses">
      <div class="p-4 pt-0">
        <Alert>
          <b>No tienes ninguna experiencia</b>
          <br />
          Al añadir alguna experiencia aparecera aqui.
        </Alert>
      </div>
    </template>

    <template v-else>
      <div :key="key" v-for="(course, key) in courses">
        <div
          class="flex items-center pt-2 pb-2 pr-4 pl-4 transition-all hover:bg-gray-100 hover:shadow-lg"
        >
          <div
            class="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white"
          >
            {{ course.name[0] }}
          </div>

          <div class="ml-4 flex-initial flex-shrink-1 w-full truncate">
            <div class="text-sm font-medium text-gray-900 truncate">
              {{ course.name }}
            </div>
            <div class="text-xs text-gray-500 truncate">
              {{ course.description }}
            </div>
          </div>
          <div class="ml-4">
            <Dropdown>
              <template #activator>
                <DotsVertical class="text-red bg-red w-5" />
              </template>
              <template #content>
                <DropdownMenuItem
                  is="router-link"
                  :to="{ name: 'courses.edit', params: { id: key } }"
                >
                  Editar
                </DropdownMenuItem>
                <DropdownMenuItem @click="confirmDeleteCourse(key)">
                  Eliminar
                </DropdownMenuItem>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal v-model:isOpen="isOpenModalDeleteCourse" type="warning">
        <template #title>Desea eliminar el curso?</template>
        <template #content>
          Se eliminara el curso permanentemente y no podra recuperar la
          informacion.
        </template>
        <template #footer>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
            @click="deleteCourse"
          >
            Eliminar
          </button>
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="isOpenModalDeleteCourse = false"
          >
            Cancellar
          </button>
        </template>
      </Modal>
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'

// Experiences store
import { EXPERIENCES_STORE_NAME } from '@/store/modules/experiences'
import { DELETE_EXPERIENCE } from '@/store/modules/experiences/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import DotsVertical from '@/components/icons/heroicons/DotsVertical.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import Button from '@/components/common/button/Button.vue'
import Modal from '@/components/common/modal/Modal.vue'
import Alert from '@/components/common/alert/Alert.vue'

export default {
  components: {
    Button,
    DotsVertical,
    Dropdown,
    DropdownMenuItem,
    Modal,
    Alert
  },
  setup() {
    const store = useStore()
    const experiences = computed(
      () => store.getters[EXPERIENCES_STORE_NAME + '/data']
    )
    const isOpenModalDeleteExperience = ref(false)
    const experienceKey = ref(null)

    const isLoading = computed(
      () => store.getters[EXPERIENCES_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[EXPERIENCES_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[EXPERIENCES_STORE_NAME + '/isReady']
    )

    const confirmDeleteCourse = key => {
      experienceKey.value = key
      isOpenModalDeleteExperience.value = true
    }
    const deleteCourse = async () => {
      await store.dispatch(
        EXPERIENCES_STORE_NAME + '/' + DELETE_EXPERIENCE,
        experienceKey.value
      )

      if (isReady.value) {
        experienceKey.value = null
        isOpenModalDeleteExperience.value = false
        pushNotification({
          title: 'Experiencia eliminada!',
          desciption: 'Tu experiencia se ha eliminado correctamente ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    return {
      experiences,
      confirmDeleteCourse,
      isOpenModalDeleteExperience,
      deleteCourse,
      isLoading
    }
  }
}
</script>
